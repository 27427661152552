// colors for reference
$white: #fff !default;
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #55bfd8 !default;

// border radiuses
$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;

// bootstrap settings
$enable-shadows: true;
$enable-gradients: true;
$enable-rounded: true;
$enable-transitions: true;

// card


@import "node_modules/bootstrap/scss/bootstrap";
