.react-bootstrap-table {
  overflow-x: scroll;

  table {
    table-layout: initial !important;
    margin-bottom: 0;
  }

  th {
    white-space: nowrap;
  }
}

.manage-profiles-view-portfolio-table {
  max-height: 300px;
  overflow-y: scroll;
}
