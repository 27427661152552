.sidebar-container {
  width: 260px;
  height: 100vh;
  overflow-y: scroll;
}

.header-toggle-icon {
  /* this width value is also used in the SidebarHelper.tsx file */
  @media screen and (min-width: 992px) {
    display: none;
  }
}
