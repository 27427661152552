.modal-max-600 {
  max-width: 600px !important;
}

.modal-max-800 {
  max-width: 800px !important;
}

.modal-max-1200 {
  max-width: 1200px !important;
}

.modal-max-1600 {
  max-width: 1600px !important;
}

.modal-max-1800 {
  max-width: 1800px !important;
}
