@import "./bootstrap-override";
@import "./sidebar.scss";
@import "./table.scss";
@import "./checkbox.scss";
@import "./modals.scss";

body {
  overflow-x: hidden;
}

th:focus {
  outline: none !important;
}
